//@ts-ignore
import PitchDetect from "pitch-detect";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const usePitchDetect = () => {
    const pitchDetect = useRef<any>(null);
    const [stream, setStream] = useState<MediaStream>();
    const [note, setNote] = useState('');

    const handleNoteChange = useCallback(() => {
        if (!stream) return;
        const n = pitchDetect.current?.getPitch()?.note;
        
        if (n) {
            setNote(n);
        }
        requestAnimationFrame(handleNoteChange)
    }, [stream])

    useEffect(() => {
        if (stream) {
            pitchDetect.current = new PitchDetect(stream);
            requestAnimationFrame(handleNoteChange)
        }
    }, [stream]);

    return {
        setStream,
        note,
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoteTrainer_noteWrapper__wRLSa {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n  height: 3rem;\n  width: 3rem;\n  box-shadow: 0px 28px 31px rgba(109, 109, 112, 0.1); }\n\n.NoteTrainer_modeBtn__Gac-Q {\n  cursor: pointer; }\n  .NoteTrainer_modeBtn__Gac-Q.NoteTrainer_active__pOK\\+n {\n    background: white; }\n", "",{"version":3,"sources":["webpack://./src/components/NoteTrainer/NoteTrainer.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EAEvB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,kDAAkD,EAAA;;AAGtD;EACI,eAAe,EAAA;EADnB;IAGQ,iBAAiB,EAAA","sourcesContent":[".noteWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    // background-color: red;\n    padding: 1rem;\n    height: 3rem;\n    width: 3rem;\n    box-shadow: 0px 28px 31px rgba(109, 109, 112, 0.1);\n}\n\n.modeBtn {\n    cursor: pointer;\n    &.active {\n        background: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noteWrapper": "NoteTrainer_noteWrapper__wRLSa",
	"modeBtn": "NoteTrainer_modeBtn__Gac-Q",
	"active": "NoteTrainer_active__pOK+n"
};
export default ___CSS_LOADER_EXPORT___;

import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePitchDetect } from "../../hooks/usePitchDetect";
import styles from "./NoteTrainer.module.scss";
import classNames from "classnames";

const WHITE_BUTTONS = ['C', 'D', 'E', 'F', 'G', 'A', 'B'];
const BLACK_BUTTONS = ['C#', 'D#', 'F#', 'G#', 'A#', 'Db', 'Eb', 'Gb', 'Ab', 'Bb'];
const BLACK_AND_WHITE_BUTTONS = WHITE_BUTTONS.concat(BLACK_BUTTONS);
const REPLACE_KEY_MAP: { [key: string]: string } = {
    ['Db']: 'C#',
    ['Eb']: 'D#',
    ['Gb']: 'F#',
    ['Ab']: 'G#',
    ['Bb']: 'A#',
}

const MAX_NOTE_SCORE = 50;
const SUCCESS_TIME = 500;
const CHECK_INTERVAL = SUCCESS_TIME / MAX_NOTE_SCORE;

//https://github.com/stackswithans/akkorder

enum LearnModes {
    white = 'white',
    black = 'black',
    blackAndWhite = 'blackAndWhite',
};

const getNote = (n: string) => REPLACE_KEY_MAP[n] || n;

export const NoteTrainer: React.FC = () => {
    const [started, setStarted] = useState(false);
    const [_stream, _setStream] = useState<MediaStream>();
    const { setStream, note } = usePitchDetect();
    const [currentNote, setCurrentNote] = useState('');
    const [noteScore, setNoteScore] = useState(0);
    const successTimer = useRef(0);
    const [totalScore, setTotalScore] = useState(0);
    const [mode, setMode] = useState(LearnModes.blackAndWhite);

    const BUTTONS = useMemo(() => {
        if (mode === LearnModes.black) {
            return BLACK_BUTTONS;
        }
        if (mode === LearnModes.white) {
            return WHITE_BUTTONS;
        }
        return BLACK_AND_WHITE_BUTTONS;
    }, [mode])

    const requestStream = useCallback(() => {
        navigator.mediaDevices.getUserMedia({
            audio: true,
        }).then(s => _setStream(s));
    }, []);

    const getNextNote = useCallback(() => {
        setCurrentNote(BUTTONS[Math.floor(Math.random() * BUTTONS.length)]);
    }, [BUTTONS])

    useEffect(() => {
        requestStream()
    }, []);

    useEffect(() => {
        if (_stream) {
            setStream(_stream);
        }
    }, [_stream]);

    useEffect(() => {
        if (started) {
            getNextNote();
        }
    }, [started, BUTTONS]);

    useEffect(() => {
        if (noteScore >= MAX_NOTE_SCORE) {
            getNextNote();
            setNoteScore(0)
            setTotalScore(p => p + 1)
        }
    }, [noteScore, BUTTONS]);

    const handleCheckScore = useCallback(() => {
        setNoteScore(p => Math.max(note === getNote(currentNote) ? (p + 1) : (p - 1), 0));
    }, [note, currentNote])

    useEffect(() => {
        if (!note || !currentNote || !started) return;
        clearInterval(successTimer.current)
        successTimer.current = window.setInterval(handleCheckScore, CHECK_INTERVAL);
    }, [currentNote, note, started])

    const successPercent = useMemo(() => 100 * noteScore / MAX_NOTE_SCORE, [noteScore])

    return <Grid container justifyContent={'center'} alignItems={"center"}>
        {started && currentNote ? <Box flexDirection={'column'} gap="1rem" display="flex" justifyContent="center" alignItems={"center"}>
            <div style={{ position: 'absolute', left: 0, top: 0 }}>
                <Typography>score: {totalScore} </Typography>
            </div>
            <Box className={styles.noteWrapper} style={{ background: `linear-gradient(to top, #bff386 ${successPercent}%, white ${0}%)` }}>
                <Typography variant="h2">{currentNote}</Typography>
            </Box>
            <Button variant="contained" onClick={() => setStarted(false)}>
                Закончить
            </Button>
        </Box> : <Box flexDirection={'column'}>
            <Box>
                <Typography>
                    Необходимо найти и нажать укзанную клавишу на вашем синтизаторе.
                </Typography>
                {!_stream && <Typography variant="caption">
                    Предоставьте доступ к вашему микрофону
                </Typography>}
            </Box>
            <Button variant="contained" onClick={() => setStarted(true)}>
                    Начать тренировку!
                </Button>
            <div>
                Клавиши
                <div>
                    <button onClick={() => setMode(LearnModes.white)} className={classNames(styles.modeBtn, { [styles.active]: mode === LearnModes.white })}>
                        Б
                    </button>
                    <button onClick={() => setMode(LearnModes.black)} className={classNames(styles.modeBtn, { [styles.active]: mode === LearnModes.black })}>
                        Ч
                    </button>
                    <button onClick={() => setMode(LearnModes.blackAndWhite)} className={classNames(styles.modeBtn, { [styles.active]: mode === LearnModes.blackAndWhite })}>
                        Б+Ч
                    </button>
                </div>
            </div>
        </Box>}
    </Grid>
}
